import Logo from '../../assets/logo512.png'
import './Loading.css'

function Loading() {
  return (
    <div className="loading">
      <img src={Logo} alt="Loading.." className="loading-logo" />
      <h3 className="loading-text">Loading...</h3>
    </div>
  )
}

export default Loading
