import { Route, Routes } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import './App.css'
import Loading from './components/Loading/Loading'

const Home = lazy(() => import('./pages/Home/Home'))
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'))
const Services = lazy(() => import('./pages/Services/Services'))
const WorkGallery = lazy(() => import('./pages/WorkGallery/WorkGallery'))
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'))
const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
const Header = lazy(() => import('./components/Header/Header'))
const Footer = lazy(() => import('./components/Footer/Footer'))

function App() {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Header />
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/workgallery" element={<WorkGallery />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </>
        <Footer />
      </Suspense>
    </>
  )
}

export default App
